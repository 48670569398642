/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
@media (max-width: 750px ) {
  .NachlesesView .AbEventAndNachlese__inner {
    grid-template-areas: "b b a a" "c c c c";
  }
}
.NachlesesView .AbEventAndNachlese__dateTime {
  text-align: right;
}
